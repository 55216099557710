import React from 'react'
import App from 'next/app'
import * as Sentry from '@sentry/node'
import '../styles/editor.sass'
import '@react-page/plugins-slate/lib/index.css'
import '@react-page/plugins-image/lib/index.css'
import '@react-page/core/lib/index.css'
import 'react-datepicker/dist/react-datepicker.css'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
  release: process.env.RELEASE_VERSION,
  enabled: process.env.ENVIRONMENT !== 'development',
  maxBreadcrumbs: 50,
  attachStacktrace: true,
})

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props as any
    const modifiedPageProps = { ...pageProps, err }

    return <Component {...modifiedPageProps} />
  }
}

export default MyApp
